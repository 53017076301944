/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Row of simple toggles and buttons for the hardware test
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.ToggleRow
 */

import { IAction, useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext, useEffect, useRef } from "react";
import { useLocalAudioToggle, useLocalVideoToggle, useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import BlurryBackground from "~/icons/blurryBackground";
import Camera from "~/icons/camera";
import Gear from "~/icons/gear";
import Mic from "~/icons/mic";
import { useHardwareTestState } from "~/state";
import { useCombinedSelectors } from "~/state/combined";
import { hardwareTestActions } from "~/state/hardwareTest";
import { DeviceStatus, DisplayContext, HardwareTestTab } from "~/types";
import { VideoSessionContext } from "~/web-core/components";
import TestSpeakerButton from "../HardwareSetup/Devices/TestSpeakerButton";
import ToggleSwitch from "../HardwareSetup/Devices/ToggleSwitch";
import { HardwareSetupDisplayContext } from "../HardwareSetup/HardwareSetup";
import ActionButton from "../Utilities/ActionButton";
import Tooltip from "../Utilities/Tooltip";
import styles from "./ToggleRow.module.scss";

interface IProps {
	onSpeakerTest: () => void;
}

enum TokenNames {
	backgroundsLabel = "BackgroundsLabel",
	videoToggle = "VideoToggle",
	audioToggle = "AudioToggle",
	openBackgroundsNoHotkey = "OpenBackgroundsNoHotkey",
	closeBackgroundsNoHotkey = "CloseBackgroundsNoHotkey",
	openSettingsNoHotkey = "OpenSettingsNoHotkey",
	closeSettingsNoHotkey = "CloseSettingsNoHotkey",
}

export enum ToggleRowTestIds {
	self = "ToggleRow",
}

function _openTestTab(
	tabToOpen: HardwareTestTab,
	currentTab: HardwareTestTab,
	dispatch: <T extends IAction>(action: T) => T,
): void {
	if (currentTab === tabToOpen) {
		dispatch(hardwareTestActions.setHardwareTestTab(HardwareTestTab.closed));
	} else {
		dispatch(hardwareTestActions.setHardwareTestTab(tabToOpen));
	}
}

/**
 * The ToggleRow button
 * @param props Props
 */
const ToggleRow: FC<IProps> = (props) => {
	const { onSpeakerTest } = props;
	const [videoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
	const [audioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

	const { localDeviceStream } = useContext(VideoSessionContext);
	const displayContext = useContext(HardwareSetupDisplayContext);
	const currentTab = useHardwareTestState((selectors) => selectors.getHardwareTestTab(), []);
	const dispatch = useDispatch();
	const cameraName = localDeviceStream?.getDeviceName("video");
	const micName = localDeviceStream?.getDeviceName("audio");

	const strings = useStrings("ToggleRow", Object.values(TokenNames));

	const openDevicesTab = useCallback(() => {
		_openTestTab(HardwareTestTab.devices, currentTab.tab, dispatch);
	}, [dispatch, currentTab.tab]);

	const openBackgroundsTab = useCallback(() => {
		_openTestTab(HardwareTestTab.backgrounds, currentTab.tab, dispatch);
	}, [dispatch, currentTab.tab]);

	const isCameraSuccess =
		useHardwareTestState((selectors) => selectors.getCameraStatus(false), []) === DeviceStatus.success;
	const isBackgroundsSupported = useCombinedSelectors(
		(selectors) => selectors.getIsBackgroundsSupported(),
		[],
	);

	const backgroundsButtonRef = useRef<HTMLButtonElement>(null);
	const settingsButtonRef = useRef<HTMLButtonElement>(null);

	const tabsNeedingFocus = useHardwareTestState((selectors) => selectors.getTabButtonsNeedingFocus(), []);

	// Append hotkeys to strings in non-mobile contexts
	const cameraToggleKeyboardShortcut = ["alt", "r"];
	const cameraToggleTooltipFormatted = useGetFormattedHotkeyString(
		strings[TokenNames.videoToggle],
		cameraToggleKeyboardShortcut,
	);

	const audioToggleKeyboardShortcut = ["alt", "m"];
	const audioToggleTooltipFormatted = useGetFormattedHotkeyString(
		strings[TokenNames.audioToggle],
		audioToggleKeyboardShortcut,
	);

	const backgroundsToggleKeyboardShortcut = ["alt", "b"];
	const backgroundString =
		currentTab.tab === HardwareTestTab.backgrounds
			? strings[TokenNames.closeBackgroundsNoHotkey]
			: strings[TokenNames.openBackgroundsNoHotkey];
	const backgroundsTooltipFormatted = useGetFormattedHotkeyString(
		backgroundString,
		backgroundsToggleKeyboardShortcut,
	);

	const settingsToggleKeyboardShortcut = ["alt", "s"];
	const settingsString =
		currentTab.tab === HardwareTestTab.devices
			? strings[TokenNames.closeSettingsNoHotkey]
			: strings[TokenNames.openSettingsNoHotkey];
	const settingsTooltipFormatted = useGetFormattedHotkeyString(
		settingsString,
		settingsToggleKeyboardShortcut,
	);

	useEffect(() => {
		if (tabsNeedingFocus.includes(HardwareTestTab.devices)) {
			settingsButtonRef.current?.focus();
			dispatch(hardwareTestActions.removeElementNeedsFocus(HardwareTestTab.devices));
		}

		if (tabsNeedingFocus.includes(HardwareTestTab.backgrounds)) {
			backgroundsButtonRef.current?.focus();
			dispatch(hardwareTestActions.removeElementNeedsFocus(HardwareTestTab.backgrounds));
		}
	}, [dispatch, tabsNeedingFocus]);
	const cameraIconRef = useRef<HTMLDivElement>(null);
	const micIconRef = useRef<HTMLDivElement>(null);

	return (
		<div className={styles["quickActions"]}>
			<div className={styles["toggleGroup"]}>
				<div className={styles["deviceToggle"]}>
					<div className={styles["deviceIconContainer"]} ref={cameraIconRef}>
						<Camera height={32} width={32} />
						{cameraName && (
							<Tooltip
								elementWithTooltip={cameraIconRef.current}
								text={cameraName}
								direction="left"
							/>
						)}
					</div>
					<ToggleSwitch
						on={videoEnabled}
						onToggle={toggleVideoEnabled}
						ariaLabel={cameraToggleTooltipFormatted}
						keyboardShortcut={cameraToggleKeyboardShortcut}
						tooltipInfo={{ text: cameraToggleTooltipFormatted, direction: "left" }}
					/>
				</div>

				<div className={styles["deviceToggle"]}>
					<div className={styles["deviceIconContainer"]} ref={micIconRef}>
						<Mic height={32} width={32} />
						{micName && (
							<Tooltip
								elementWithTooltip={micIconRef.current}
								text={micName}
								direction="left"
							/>
						)}
					</div>
					<ToggleSwitch
						on={audioEnabled}
						onToggle={toggleAudioEnabled}
						ariaLabel={audioToggleTooltipFormatted}
						keyboardShortcut={audioToggleKeyboardShortcut}
						tooltipInfo={{ text: audioToggleTooltipFormatted, direction: "left" }}
					/>
				</div>
			</div>

			<div className={styles["toggleGroup"]}>
				<TestSpeakerButton onAudioPlayed={onSpeakerTest} />
			</div>

			<div role="menubar" className={styles["settingsViewButtons"]}>
				{isBackgroundsSupported && isCameraSuccess && displayContext === DisplayContext.lobby && (
					<ActionButton
						tone="neutral"
						priority="secondary"
						icon={BlurryBackground}
						onClick={openBackgroundsTab}
						active={currentTab.tab === HardwareTestTab.backgrounds}
						aria-label={backgroundsTooltipFormatted}
						aria-controls="backgroundsMenu"
						keyboardShortcut={backgroundsToggleKeyboardShortcut}
						text={strings[TokenNames.backgroundsLabel]}
						slim
						buttonRef={backgroundsButtonRef}
						id="backgrounds-menu-button"
					/>
				)}
				<ActionButton
					tone="neutral"
					priority="secondary"
					icon={Gear}
					onClick={openDevicesTab}
					active={currentTab.tab === HardwareTestTab.devices}
					keyboardShortcut={settingsToggleKeyboardShortcut}
					aria-label={settingsTooltipFormatted}
					aria-controls="settingsMenu"
					tooltipInfo={{
						text: settingsTooltipFormatted,
						direction: "right",
					}}
					slim
					buttonRef={settingsButtonRef}
					id="settings-menu-button"
				/>
			</div>
		</div>
	);
};

export const ToggleRowUnitTestingMethods = { openTestTab: _openTestTab };

export default ToggleRow;
