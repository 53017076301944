/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file All functions needed to capture images and format them for saving to a patient's chart
 * @author Will Cooper
 * @module Epic.VideoApp.Utils.ImageCapture
 */

import { ITelemedicineDocType } from "~/types";
import { stringToBuffer } from "./general";
import { makeRequest } from "./request";

/**
 * Determines available Image Capture method to capture the screenshot.
 * We return a Base64 string to ensure that we can easily display the string to the client.
 * Our two methods (ImageCapture API vs Canvas method) return different data types.
 * We will need to convert the Base64 string back to Blob/Arraybuffer format before saving images to the server.
 *
 * @param video - The video media stream to capture from
 * @returns - The raw image data of the captured image in base64 string.
 */
export async function captureImage(video: MediaStreamTrack): Promise<string> {
	// Try to use the browser's MediaStream Image Capture API - works in Chrome, Edge, Opera
	// https://developer.mozilla.org/en-US/docs/Web/API/MediaStream_Image_Capture_API
	if (isImageCaptureAvailable()) {
		// We may have browsers that support ImageCapture but fail at runtime (32-bit Chrome vs 64-bit Chrome)
		try {
			// eslint-disable-next-line compat/compat
			const captureInterface = new ImageCapture(video);
			const blob = await captureInterface.takePhoto();
			return await readImageBlobAsync(blob);
		} catch {
			return captureCanvasImage();
		}
	} else {
		// use canvas method
		// Fallback to canvas solution if the above API fails or is unavailable
		return captureCanvasImage();
	}
}

/**
 * Takes an image Blob and returns it as a Base64 string.
 *
 * @param blob - The raw image Blob to read from
 * @returns - The raw base64 representation of the captured image.
 */
async function readImageBlobAsync(blob: Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function () {
			const imageData = reader.result?.toString();
			if (imageData) {
				resolve(imageData);
			}
			resolve("");
		};

		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
}

/**
 * Renders an HTML Video Element to an off-screen canvas and captures a single image from this.
 *
 * @returns - The base64 string of raw image data captured from the canvas element
 */
function captureCanvasImage(): string {
	const canvas = document.createElement("canvas");
	const videoFeed = document.getElementById("main-video") as HTMLVideoElement;
	canvas.width = videoFeed.videoWidth;
	canvas.height = videoFeed.videoHeight;
	canvas.getContext("2d")?.drawImage(videoFeed, 0, 0);
	// Remove header information "data:image/png;base64," from raw image data
	return canvas.toDataURL();
}

/**
 * We want to only store the raw image data.
 * The first few characters will be identifying meta data that we do not want to log with our request.
 *
 * @param imageData - The Base64 string representing image data
 * @returns - The image data stripped of any metadata
 */
function stripMetaData(imageData: string): string {
	const image = imageData.split(",");
	if (image.length > 0) {
		return image[1];
	}
	return image[0];
}

/**
 * Convert the base64 string representation of data (in this case captured images) into an arraybuffer (charCodes/bytes)
 * that can be directly passed from our Azure endpoint to Interconnect
 *
 * @param input Base64 string to convert to byte values
 * @returns An ArrayBuffer representing the data in the
 */
function convertBase64ToBuffer(input: string): ArrayBuffer {
	return stringToBuffer(window.atob(input));
}

/**
 * Will check any available document types for the expected default.
 *
 * @param documentTypes - The list of available document types for the current user
 * @returns - The default document type if found in the list, or if no list is provided, or the first type in the list.
 */
export function selectDefaultDocumentType(
	documentTypes: ITelemedicineDocType[],
): ITelemedicineDocType | null {
	if (!documentTypes || !documentTypes.length) {
		return null;
	}

	const foundDefault = documentTypes.find((type) => type.id === getDefaultDocumentType().id);

	// Check if this should be alphabetical order if we need
	return foundDefault ? foundDefault : documentTypes[0];
}

/**
 * Returns the default DCS Document Type available for saving images
 * @returns The hardcoded default document type
 */
export function getDefaultDocumentType(): ITelemedicineDocType {
	return { id: "15", title: "Clinical Unknown" };
}

/**
 * Sort function for comparing document types by title
 *
 * @param docA - One doctype check
 * @param docB - The doctype to compare the first to
 * @returns - -1 if B sorts first, 0 if identical, and 1 if A sorts first
 */
export function alphabetizeDocTypes(docA: ITelemedicineDocType, docB: ITelemedicineDocType): number {
	const first = docA.title.toUpperCase();
	const second = docB.title.toUpperCase();
	if (first < second) {
		return -1;
	}
	if (first > second) {
		return 1;
	}
	return 0;
}

/**
 * Checks whether the ImageCapture interface is available in the current browser
 *
 * @returns true if available, false otherwise
 */
export function isImageCaptureAvailable(): boolean {
	return typeof ImageCapture !== "undefined";
}

/**
 * Sends a web request to store an image capture
 * @param image The string of the image
 * @param jwt The session jwt
 * @param docType The document type
 * @param description User entered description to save with the image
 * @param participantId The user id of the participant who's image was captured
 */
export async function storeCapturedImage(
	image: string,
	jwt: string,
	docType: string,
	description: string,
	participantId: string,
): Promise<void> {
	const buffer = convertBase64ToBuffer(stripMetaData(image));
	await makeRequest("/api/VideoCall/SaveMedia", "POST", jwt, buffer, {
		queryStringData: { documentType: docType, description, participantId },
		contentType: "image/png",
	});
}
