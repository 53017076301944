/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file types for the core stream interface
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Types.Stream
 */

export type DeviceKind = "audio" | "video";

export type DeviceType = "camera" | "mic" | "speaker";

const videoTypes = ["camera", "screen"] as const;
export type VideoType = (typeof videoTypes)[number];

export type IStreamSubscriptionStatus = {
	[key in (typeof videoTypes)[number]]: SubscriptionStatus;
};

export type Priority = "low" | "standard" | "high";

// These directly map to the simulcast quality layers from Daily, but they could be further mapped to other scales
// within a vendor implementation if necessary
export enum Quality {
	undefined = -1,
	low = 0,
	standard = 1,
	high = 2,
}

export enum SubscriptionStatus {
	subscribed = 0,
	staged = 1,
	unsubscribed = 2,
}

export interface ISwitchDeviceResponse {
	result: boolean;
	switchedDevices: boolean;
	error: Error | undefined;
}
