/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Hook to handle picture-in-picture events at room level
 * @author Arun Vasireddy
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UsePictureInPictureRoom
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useContext, useEffect } from "react";
import { pipActions, usePictureInPictureState, useUIState } from "~/state";
import { exitPictureInPictureMode, isPiPWindowOpen } from "~/utils/pictureInPicture";
import { ParticipantDataContext, ParticipantStatusContext } from "~/web-core/components";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { VideoType } from "~/web-core/types";

/**
 * Manage picture-in-picture actions at room level
 */
export function usePictureInPictureRoom(): void {
	const participantInPiP = usePictureInPictureState((selectors) => selectors.getPipVideoID(), []);
	const pipVideoType = usePictureInPictureState((selectors) => selectors.getPipVideoType(), []);
	const isPiPModeEnabled = usePictureInPictureState((selectors) => selectors.getPipStatus(), []);
	const { participants } = useContext(ParticipantDataContext);
	const { mainParticipant } = useContext(ParticipantStatusContext);
	const isHeaderButtonPressed = usePictureInPictureState(
		(selectors) => selectors.getHeaderButtonPressed(),
		[],
	);
	const pinnedVideo = useUIState((selectors) => selectors.getPinnedVideo(), []);
	const isMainParticipantPinned = mainParticipant?.getUserIdentity() === pinnedVideo?.identity;
	const videoType = isMainParticipantPinned ? pinnedVideo?.videoType : "camera"; //When screen share is initiated, it'll be pinned, so, we'll update PiP window accordingly

	const isMainParticipantInPiPTrack =
		mainParticipant?.getUserIdentity() === participantInPiP && pipVideoType === videoType; //Is the main participant and/or their video type different from what is PiP'ed

	const pipUser = participants.find((participant) => participant.getUserIdentity() === participantInPiP);

	const pipParticipantsStream = useStream(pipUser, pipVideoType as VideoType);
	const isPiPParticipantStreamEnabled = useIsStreamEnabled("video", pipParticipantsStream);

	// When the PiPed user's video feed is disabled, exit PiP mode unless we PiP'ed using the header button
	// and there are other participants in the call besides the PiPed user
	const endPiPMode =
		((!isPiPModeEnabled || !isPiPParticipantStreamEnabled) &&
			(!isHeaderButtonPressed || participants.length === 1)) ||
		(!isPiPParticipantStreamEnabled && isMainParticipantPinned && isMainParticipantInPiPTrack);

	const dispatch = useDispatch();

	useEffect(() => {
		// End PiP when all participants leave the call or if the participant in PiP window leaves the call
		if (isPiPWindowOpen() && (participants.length === 0 || endPiPMode)) {
			void exitPictureInPictureMode().then(() => {
				dispatch(
					pipActions.leavePiPMode({
						videoID: participantInPiP,
						videoType: pipVideoType,
						isMainParticipant: true,
					}),
				);
			});
		}
	}, [dispatch, participantInPiP, participants, pipVideoType, endPiPMode]);

	useEffect(() => {
		// When main participant changes, if the header PiP button is pressed & main participant is not already in PiP, enter their video feed into the PiP window
		if (
			isHeaderButtonPressed &&
			mainParticipant &&
			!isMainParticipantInPiPTrack &&
			videoType &&
			isPiPWindowOpen()
		) {
			dispatch(
				pipActions.enterPiPMode({
					newVideoID: mainParticipant.getUserIdentity(),
					newVideoType: videoType,
				}),
			);
		}
	}, [dispatch, isHeaderButtonPressed, mainParticipant, isMainParticipantInPiPTrack, videoType]);

	useEffect(() => {
		// End PiP when user chose to end PiP
		if (!isPiPModeEnabled) {
			void exitPictureInPictureMode();
		}
	}, [isPiPModeEnabled]);
}
