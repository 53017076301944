/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Base interface for the video call session
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.Session
 */

import { IEVCEmitter, IEVCSessionEventMap } from "../events";
import { DeviceKind, SessionConnectionStatus } from "../types";
import { IConnectOptions } from "../types/connectOptions";
import { ILocalStream } from "./localStream";
import { ILocalUser } from "./localUser";
import { IRemoteUser } from "./remoteUser";
import { VendorError } from "./vendorError";

/**
 * @interface ISession
 * @extends IEVCEmitter<IEVCSessionEventMap>
 * @description Interface for a session in the video application. It extends the IEVCEmitter
 * interface and adds properties and methods related to session management, user interaction, and error handling.
 */
export interface ISession extends IEVCEmitter<IEVCSessionEventMap> {
	/**
	 * @property localUser
	 * @type ILocalUser
	 * @description The local user associated with this session.
	 */
	localUser: ILocalUser;

	/**
	 * @property roomGuid
	 * @type string | undefined
	 * @description The unique identifier for the room associated with this session. It can be undefined
	 * if the session is not associated with a room.
	 */
	roomGuid?: string;

	/**
	 * @property connectionStatus
	 * @type SessionConnectionStatus
	 * @description The current connection status of the session.
	 */
	connectionStatus: SessionConnectionStatus;

	/**
	 * @method connect
	 * @param {IConnectOptions} connectOptions - The options for connecting to the session.
	 * @returns whether or not the connection was successful.
	 * @description Connects to the session using the provided options.
	 */
	connect(connectOptions: IConnectOptions): Promise<boolean>;

	/**
	 * @method disconnect
	 * @description Disconnects from the session.
	 */
	disconnect(): void;

	/**
	 * @method refreshMedia
	 * @description Refreshes the media in the session.
	 */
	refreshMedia(): void;

	/**
	 * @method publish
	 * @param {DeviceKind} kind - The kind of the device to publish (audio or video).
	 * @param {boolean} expectedState - Whether to publish or unpublish.
	 * @param {ILocalStream} device - The local stream to publish.
	 * @returns a boolean indicating whether the operation was successful.
	 * @description Publishes or un-publishes a local stream to the session based on the provided kind,
	 * expected state, and device.
	 */
	publish(kind: DeviceKind, expectedState: boolean, device: ILocalStream): Promise<boolean>;

	/**
	 * @method getRoomInfo
	 * @param {string} jwt - The JSON Web Token for the room.
	 * @returns a Promise with information about the room.
	 * @description Gets information about the room associated with the provided JSON Web Token and
	 * returns it as a Promise.
	 */
	getRoomInfo(jwt: string): Promise<IConnectOptions>;

	/**
	 * @method getRemoteParticipants
	 * @returns as array of IRemoteUser currently in the session.
	 */
	getRemoteParticipants(): IRemoteUser[];

	/**
	 * @method getRemoteParticipant
	 * @param Identity string for the user to attempt to fetch
	 * @returns IRemoteUser | null
	 * @description Gets the remote user with the provided identity. Returns null if the user is not found.
	 */
	getRemoteParticipant(identity: string): IRemoteUser | null;

	/**
	 * @method getLocalParticipant
	 * @returns the local user in the session or null if there is no local user.
	 */
	getLocalParticipant(): ILocalUser | null;

	/**
	 * @method getDominantSpeaker
	 * @returns the dominant speaker in the session or null if there is no dominant speaker.
	 */
	getDominantSpeaker(): IRemoteUser | null;

	/**
	 * @method sendDataMessage
	 * @param {string} message - The message to send.
	 * @description Sends a data message to the session.
	 */
	sendDataMessage(message: string): void;

	/**
	 * @method addScreenShare
	 * @param {ILocalStream} stream - The local stream to share.
	 * @returns whether or not the operation was successful.
	 * @description Adds a screen share to the session using the provided local stream.
	 */
	addScreenShare(stream: ILocalStream): Promise<boolean>;

	/**
	 * @method removeScreenShare
	 * @param {ILocalStream} stream - The local stream to stop sharing.
	 * @param {boolean | undefined} removeAudio - Whether to also remove audio. It can be undefined, in
	 * which case only the video is removed.
	 * @returns whether or not the operation was successful.
	 * @description Removes a screen share from the session using the provided local stream and optional
	 * removeAudio parameter.
	 */
	removeScreenShare(stream: ILocalStream, removeAudio?: boolean): Promise<boolean>;

	/**
	 * @method enableUserMedia
	 * @param {string} identity - The identity of the user.
	 * @param {string} jwt - The JSON Web Token for the user.
	 * @returns whether or not the operation was successful.
	 * @description Enables media for the user associated with the provided identity and JSON Web Token,
	 */
	enableUserMedia(identity: string, jwt: string): Promise<boolean>;

	/**
	 * @method processError
	 * @param {unknown} error - The error to process.
	 * @returns a VendorError or undefined if the error could not be processed.
	 * @description Processes the provided error
	 */
	processError(error: unknown): VendorError | undefined;
}
