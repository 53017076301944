/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Video Output for generic vendor
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Components.VideoStream
 */

import React, { FC, useContext, useEffect, useMemo, useRef } from "react";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";
import { usePictureInPicture } from "~/hooks";
import useVideoStreamDimensions from "~/hooks/trackUtilities/useVideoStreamDimensions";
import { IDimensions } from "~/types";
import { resolveClassName } from "~/utils/className";
import { iOSDetectedAtVersion } from "~/utils/os";
import { isTrackFrontFacing } from "~/utils/video";
import { IStream } from "~/web-core/interfaces";
import { useMediaTrack } from "../hooks/useMediaTrack";
import { VideoType } from "../types";
import { TwilioLocalStream } from "../vendor/twilio/implementations";
import styles from "./VideoStream.module.scss";

export enum VideoStreamTestIds {
	self = "VideoStream",
}

interface IProps {
	stream: IStream;
	className?: string;
	isLocal?: boolean;
	mainParticipant?: boolean;
	userId?: string;
	hideLoading?: boolean;
	lightBackground?: boolean;
	videoSize?: IDimensions;
	videoType?: VideoType;
	disablePictureInPicture?: boolean;
}

/**
 * The VideoStream component
 */
const VideoStream: FC<IProps> = (props: IProps) => {
	const {
		stream,
		isLocal,
		mainParticipant,
		userId,
		videoSize,
		className,
		videoType,
		disablePictureInPicture,
	} = props;
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const isSharedScreen = videoType === "screen";
	usePictureInPicture(isLocal ? null : videoRef, userId, videoType);
	const dimensions = useVideoStreamDimensions(stream);
	const isPortrait = dimensions.height > dimensions.width;
	const restartLockoutRef = useRef(false);
	const { isVisible } = useContext(DeviceContext);
	const mediaTrack = useMediaTrack(stream, "video");

	// Set up event handlers for video playback
	useEffect(() => {
		const video = videoRef.current;
		if (!video) {
			return;
		}

		const handlePause = (): void => {
			/** iOS: sometimes interruptions pause HTMLMediaElements */
			if (!document.hidden) {
				void video.play();
			}
		};

		const handleCanPlay = (): void => {
			if (!video.paused) {
				return;
			}
			void video.play();
		};

		const handleVisibilityChange = (): void => {
			if (document.visibilityState === "hidden") {
				return;
			}
			if (!video.paused) {
				return;
			}
			void video.play();
		};

		const handleLoadStart = (): void => {
			/** iOS 15+: if we background & return quickly, the proper mute and visibilitychange events may not
			 * be emitted, so the MST will be muted but our application unaware. Try to detect that here
			 * https://github.com/twilio/twilio-video.js/blob/d97a4d91696bb79edb34716892c79f2c38707888/lib/media/track/localvideotrack.js#L302
			 */
			if (
				iOSDetectedAtVersion("15+") &&
				!restartLockoutRef.current &&
				isVisible &&
				isLocal &&
				mediaTrack?.muted
			) {
				restartLockoutRef.current = true;
				const localStream = stream as TwilioLocalStream;
				const localVideoTrack = localStream.localVideoTrack;
				const wasEnabled = localVideoTrack?.isEnabled ?? false;

				/**	having this on a timeout avoids an iOS Chrome backgrounding issue, avoiding the restart if it stays
				 * backgrounded, but recovering if there is a quick return from background */
				window.setTimeout(() => {
					// double-check document visibility to only run if no longer backgrounded
					if (!document.hidden && localVideoTrack) {
						localVideoTrack.enable(false);
						localVideoTrack.stop();
						void localVideoTrack
							.restart()
							.then(() => localVideoTrack.enable(wasEnabled))
							.finally(() => (restartLockoutRef.current = false));
					}
				}, 1000);
			}
		};

		video.onloadstart = handleLoadStart;
		video.oncanplay = handleCanPlay;
		video.onpause = handlePause;

		// Videos can be paused if media was played in another app on iOS.
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			video.oncanplay = null;
			video.onpause = null;
			video.onloadstart = null;
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, [isLocal, isVisible, mediaTrack, stream]);

	// Render the user's video feed to the DOM
	useEffect(() => {
		const videoElement = videoRef.current;
		if (!videoElement || !stream) {
			return;
		}

		stream.renderVideo(videoElement);
		return () => {
			stream.cleanupVideo(videoElement);
		};
	}, [stream, mediaTrack]);

	// Mirror the local user's front-facing video feed with a CSS style
	const isMirrored = useMemo(() => {
		if (isLocal && videoType !== "screen") {
			return !!mediaTrack && isTrackFrontFacing(mediaTrack);
		}

		return false;
	}, [isLocal, videoType, mediaTrack]);

	const videoClassName = resolveClassName(
		styles,
		{
			mirrored: isMirrored,
			portraitFit: isPortrait || isSharedScreen,
			landscapeFit: !isPortrait && !isSharedScreen,
			sharedScreen: isSharedScreen,
		},
		className,
	);
	const style = videoSize
		? {
				height: `${String(Math.floor(videoSize.height))}px`,
				width: `${String(Math.floor(videoSize.width))}px`,
				background: "black",
		  }
		: undefined;

	// Apply a unique ID to the main participant's HTML video element so we can target it for image capture
	const videoId = mainParticipant
		? "main-video"
		: isSharedScreen
		? "screen-share"
		: userId || "local-video";

	return (
		<video
			ref={videoRef}
			id={videoId}
			className={videoClassName}
			style={style}
			data-testid={VideoStreamTestIds.self}
			disablePictureInPicture={disablePictureInPicture}
			playsInline
		/>
	);
};

VideoStream.displayName = "VideoStream";

export default VideoStream;
