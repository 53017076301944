/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file Video Room component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.VideoCall.VideoRoom
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useEffect, useMemo } from "react";
import { ErrorTokenNames } from "~/features/generic-error/GenericError";
import { useDisconnect, useWindowSize } from "~/hooks";
import { useParticipantCountIframeMessage } from "~/hooks/useParticipantCountIframeMessage";
import { errorPageActions, useImageState, useUIState, useUserState } from "~/state";
import { useChatState } from "~/state/chat";
import { EpicUserType, MinimumScreenHeightForHeader, ScreenWidthInflectionPoint } from "~/types";
import { IEVCSessionDisconnectionEvent, IEVCSessionReconnectingEvent } from "~/web-core/events";
import { useStoreError } from "~/web-core/hooks/useStoreError";
import { ISession } from "~/web-core/interfaces";
import { SessionErrorCodes, VendorError } from "~/web-core/interfaces/vendorError";
import CallDurationTimer from "../CallDurationTimer/CallDurationTimer";
import ControlsHeader from "../Header/ControlsHeader";
import ImagePreviewPane from "../ImageCapture/ImagePreviewPane";
import RemoteParticipantAudio from "../Participants/Audio/RemoteParticipantAudio";
import ModeratorAlerts from "./ModeratorAlerts";
import Sidebar from "./Sidebar";
import styles from "./VideoRoom.module.scss";
import VideoSection from "./VideoSection";
import WaitingParticipantAlert from "./WaitingParticipantAlert";
import { useModeratorAlert } from "./hooks/useModeratorAlert";
import { usePictureInPictureRoom } from "./hooks/usePictureInPictureRoom";

interface IProps {
	session: ISession;
}
/**
 * The VideoRoom component
 */
const VideoRoom: FC<IProps> = (props: IProps) => {
	const { session } = props;
	const dispatch = useDispatch();
	const userType = useUserState((selectors) => selectors.getUserType(), []);
	const chatEnabled = useChatState((selectors) => selectors.getChatEnabled(), []);
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const image = useImageState((selectors) => selectors.getImageData(), []);
	const canAdmit = useUserState((selectors) => selectors.getUserPermission("canStartEndVisit"), []);
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);
	useModeratorAlert();
	usePictureInPictureRoom();
	const storeError = useStoreError();
	const disconnect = useDisconnect();
	const { width: width, height: height } = useWindowSize();
	const sidebarOpen = useUIState((selectors) => selectors.getSidebarStatus(), []) === "open";
	const shouldHideVideoFeed = useMemo(() => {
		if ((width <= ScreenWidthInflectionPoint || height <= MinimumScreenHeightForHeader) && sidebarOpen) {
			return true;
		} else {
			return false;
		}
	}, [width, height, sidebarOpen]);

	// listen to participant changes and emit updates to parent views
	useParticipantCountIframeMessage(session);

	useEffect(() => {
		if (!session) {
			return;
		}

		// Log errors when reconnecting or disconnected events occur.
		const onRoomError = (error: VendorError, disconnected?: boolean): void => {
			error.message = `Room ${disconnected ? "Disconnected" : "Reconnecting"} - ${error.message}`;
			error.updateConnectionStatus = disconnected || false;
			storeError(error);
		};

		const roomErrorHandler = (args: IEVCSessionReconnectingEvent): void => {
			const { error, wasDisconnected: disconnected } = args;
			if (!error) {
				return;
			}
			onRoomError(error, disconnected);
		};

		const onRoomDisconnected = (args: IEVCSessionDisconnectionEvent): void => {
			const { error: vendorError } = args;
			if (vendorError) {
				if (vendorError.sessionErrorCode === SessionErrorCodes.participantDuplicateIdentityError) {
					// only prevent reconnect when disconnected by joining from another device
					onRoomError(vendorError, true);
					disconnect(false, true);
					return;
				} else if (vendorError.sessionErrorCode === SessionErrorCodes.roomCompletedError) {
					// When a moderator ends the visit, users should be disconnected via sendMessage(), but this is a fallback just in case.
					dispatch(
						errorPageActions.setErrorCard({
							title: ErrorTokenNames.endedTitle,
							message: ErrorTokenNames.endedBody,
						}),
					);
					// Don't log a room error when the visit was ended
					disconnect(false);
					return;
				} else if (vendorError.sessionErrorCode === SessionErrorCodes.participantRemovedFromSession) {
					dispatch(
						errorPageActions.setErrorCard({
							title: ErrorTokenNames.participantRemovedTitle,
							message:
								userType === EpicUserType.emp
									? ErrorTokenNames.participantRemovedBodyRejoin
									: ErrorTokenNames.participantRemovedBody,
						}),
					);
					disconnect(false, true); // When a user is removed, prevent reconnect
					return;
				} else {
					// Navigate to disconnected, but show an error message
					onRoomError(vendorError, true);
					dispatch(
						errorPageActions.setErrorCard({
							title: ErrorTokenNames.genericHeader,
							message: ErrorTokenNames.genericMessage,
						}),
					);
					disconnect(false);
					return;
				}
			}

			// We fall through when a disconnect occurs and error is undefined (i.e. the client disconnects itself)
			// It's not necessary to call disconnect here since we've already called it (it's how we got here)
		};

		session.on("disconnected", onRoomDisconnected);
		session.on("reconnecting", roomErrorHandler);
		return () => {
			session.off("disconnected", onRoomDisconnected);
			session.off("reconnecting", roomErrorHandler);
		};
	}, [session, dispatch, storeError, userType, disconnect]);

	// to ensure the timer continues to count even if the header is hidden, the CallDurationTimer will always be in the DOM here, but the
	// CallDurationTimerDestination in ControlsHeader can conditionally be in the DOM
	return (
		<div className={styles["videoRoom"]}>
			<ControlsHeader />
			{image && <ImagePreviewPane image={image} />}
			<main>
				{/* Add audio playback per-participant. Ensure it remains separate from the visible UI*/}
				{/* If the user is in the waiting room, no need to render audio*/}
				{/* Avoids inconsistent behavior surrounding admittance with backgrounded iOS devices*/}
				{!inWaitingRoom && <RemoteParticipantAudio />}
				<div style={{ display: "flex", height: "100%" }}>
					{!(chatEnabled && shouldHideVideoFeed) && <VideoSection hasImage={!!image} />}
					{chatEnabled && !inWaitingRoom && <Sidebar />}
				</div>
			</main>

			{userType === EpicUserType.emp && <CallDurationTimer />}
			{canAdmit && <WaitingParticipantAlert />}
			{canModerate && <ModeratorAlerts />}
		</div>
	);
};

VideoRoom.displayName = "VideoRoom";

export default VideoRoom;
