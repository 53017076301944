/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file BackgroundGalleryNoneOption
 * @author Liam Liden
 * @module Epic.VideoApp.Components.BackgroundSelector.BackgroundGalleryNoneOption
 */

import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { useBackgroundToggle } from "~/hooks/backgroundEffects";
import error from "~/icons/error";
import { DefaultImageNames } from "~/types/backgrounds";
import { resolveClassName } from "~/utils/className";
import { ILocalStream } from "~/web-core/interfaces";
import BaseButton from "../Utilities/BaseButton";
import styles from "./BackgroundGalleryNoneOption.module.scss";

interface IProps {
	/** Track to remove background effects from */
	applyStream: ILocalStream | null;

	ariaLabel?: string;
}

enum TokenNames {
	backgroundNoneOption = "BackgroundNoneOption",
}

/**
 * The BackgroundGalleryNoneOption component
 */
const BackgroundGalleryNoneOption: FC<IProps> = (props: IProps) => {
	const { applyStream, ariaLabel } = props;
	const { isEnabled, toggleProcessor } = useBackgroundToggle(DefaultImageNames.none, applyStream);

	const selectedClassName = resolveClassName(styles, {
		noneOption: true,
		selected: isEnabled,
	});

	const clearProcessor = useCallback(() => {
		toggleProcessor("on");
	}, [toggleProcessor]);

	const strings = useStrings("BackgroundGalleryNoneOption", Object.values(TokenNames));

	return (
		<div className={styles["noneOptionRow"]}>
			<BaseButton
				rawClass={selectedClassName}
				onClick={clearProcessor}
				text={strings[TokenNames.backgroundNoneOption]}
				icon={error}
				role="menuitemradio"
				aria-label={ariaLabel}
				aria-checked={isEnabled}
			/>
		</div>
	);
};

BackgroundGalleryNoneOption.displayName = "BackgroundGalleryNoneOption";

export default BackgroundGalleryNoneOption;
