/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to manage the event listener for device updates
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UseDeviceList
 */

import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAutomaticDeviceSelection, useSharedStrings } from "~/hooks";
import { useLocalTrackState } from "~/state";
import { isExcludedDevice } from "~/utils/device";
import { iOSDetectedAtVersion, isIOS } from "~/utils/os";
import { SharedStringTokens } from "~/utils/strings";
import { VideoSessionContext } from "~/web-core/components/VideoSessionProvider";

// some of our supported browsers don't support the entire mediaDevices API. That's fine.
/* eslint-disable compat/compat */

export const DefaultIOSMicId = "defaultIOSMicrophone";
export const DefaultIOSCameraId = "defaultIOSCamera";

export function useDeviceList(): MediaDeviceInfo[] {
	const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
	const isIOS15 = useMemo(() => iOSDetectedAtVersion("15+"), []);

	const localTrackAcquisitionStatus = useLocalTrackState((sel) => sel.getLocalTrackAcquisitionStatus(), []);

	const { localDeviceStream } = useContext(VideoSessionContext);

	// call automatic device selection once devices have been acquired
	const autoSelectDevices = useAutomaticDeviceSelection();
	const invokeAutoSelectDevicesRef = useRef(autoSelectDevices);

	const strings = useSharedStrings([SharedStringTokens.systemDefault]);
	const defaultStringRef = useRef(strings[SharedStringTokens.systemDefault]);

	const iOSCameraInfoRef = useRef<MediaDeviceInfo[]>([]);
	const iOSMicInfoOverride = (id?: string): MediaDeviceInfo => {
		return {
			kind: "audioinput",
			deviceId: id || DefaultIOSMicId,
			label: defaultStringRef.current,
			groupId: "",
			toJSON: () => null,
		};
	};

	const iOSMicInfoRef = useRef<MediaDeviceInfo>(iOSMicInfoOverride());

	useEffect(() => {
		invokeAutoSelectDevicesRef.current = autoSelectDevices;
	}, [autoSelectDevices]);

	/** iOS: for pre-iOS15 we don't listen for devicechange events directly. if our LocalAudioTrack's deviceId
	 * changes, update state with the new value */
	useEffect(() => {
		if (isIOS() && !isIOS15) {
			iOSMicInfoRef.current = iOSMicInfoOverride(localDeviceStream?.getDeviceId("audio"));
			setDevices([...iOSCameraInfoRef.current, iOSMicInfoRef.current]);
		}
	}, [localDeviceStream, isIOS15]);

	useEffect(() => {
		/**	don't call enumerateDevices until we've called getUserMedia/Video.createLocalTracks
		 to ensure we've asked for device permissions 		*/
		if (localTrackAcquisitionStatus !== "finished") {
			return;
		}

		const getDevices = (): void => {
			// standard device enumeration
			void navigator.mediaDevices.enumerateDevices().then((enumeratedDevices) => {
				// subset of devices we want to display as selectable in UI (filters out infrared cameras & OS Default duplicates)
				let displayedDevices = enumeratedDevices.filter(
					(device) => device.deviceId && !isExcludedDevice(device),
				);

				// devices we want to use to determine auto-selection (will include OS Default duplicates, but non-default version will be selected)
				enumeratedDevices = enumeratedDevices.filter((device) => device.deviceId);

				/** iOS 14.x: Pare enumeratedDevices to what should be only cameras (two) plus default mic info */
				if (isIOS() && !isIOS15) {
					iOSCameraInfoRef.current = enumeratedDevices.filter((m) => m.kind === "videoinput");
					enumeratedDevices = [...iOSCameraInfoRef.current, iOSMicInfoRef.current];
					displayedDevices = enumeratedDevices;
				}

				setDevices(displayedDevices);

				void invokeAutoSelectDevicesRef.current(enumeratedDevices);
			});
		};

		getDevices();

		/** For pre-iOS15 iOS devices, don't listen for devicechange (can cause ~40min crashes of MobilSafari process) */
		if (isIOS() && !isIOS15) {
			return;
		}

		navigator.mediaDevices.addEventListener("devicechange", getDevices);
		return () => {
			navigator.mediaDevices.removeEventListener("devicechange", getDevices);
		};
	}, [isIOS15, localTrackAcquisitionStatus]);

	return devices;
}
